import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import {AppConfigService} from '../../services/app.config.service';
import {AppMainMenuButtonService} from '../../services/app.main-menu-button.service';
import {AppPrintService} from '../../services/app.print.service';
import {NavigationCancel, NavigationEnd, Router, Scroll} from '@angular/router';
import {AppEventService} from '../../services/app.event.service';
import {SimplebarAngularComponent} from 'simplebar-angular';
import {AppMobileService} from "../../services/app.mobile.service";
import {takeUntil} from "rxjs/operators";
import {AppUserService} from "../../services/app.user.service";
import {AppReadyStateService} from "../../services/app.ready-state.service";
import {DashboardViewComponent} from "../../modules/dashboard/components/view/dashboard.view.component";
import {AppPageloaderService} from "../../services/app.pageloader.service";

declare let window: any;

@Component({
  templateUrl: 'app.layouts.loggedin.component.html',
  styleUrls: ['app.layouts.loggedin.component.scss']
})
export class AppLayoutsLoggedinComponent implements OnInit, OnDestroy {
  id = 'AppLayoutsLoggedinComponent';

  appVersion: any;

  showType = new BehaviorSubject<string>('router');

  active_component = new BehaviorSubject<any>(null);

  active_component$ = this.active_component.asObservable();

  show_help_button = new BehaviorSubject<boolean>(true);

  fullheight = new BehaviorSubject<boolean>(false);

  _onDestroy = new Subject();

  shop_help_button_show_paths = [
    /^\/shop\/warenkoerbe$/,
    /^\/shop\/warenkorb\/.*$/,
    /^\/shop\/merklisten$/,
    /^\/shop\/merkliste\/.*$/,
    /^\/shop\/bestellungen$/,

  ];

  readyLevel1 = new BehaviorSubject<boolean>(false);
  readyLevel2 = new BehaviorSubject<boolean>(false);

  @ViewChild(SimplebarAngularComponent) simplebar: SimplebarAngularComponent;

  @ViewChild(DashboardViewComponent) dashboard: DashboardViewComponent;

  constructor(private router: Router, public cfg: AppConfigService, public psvc: AppPrintService,
              private menubuttonsvc: AppMainMenuButtonService, private usvc: AppUserService,
              public msvc: AppMobileService, private readyStateService: AppReadyStateService,
              private esvc: AppEventService, private loader: AppPageloaderService) {
    this.appVersion = cfg.getVersion();
  }

  activate($event) {
    if ($event && $event.isFullHeight && $event.isFullHeight()) {
      this.fullheight.next(true);
    } else {
      this.fullheight.next(false);
    }
  }

  initSimplebar() {
    if (this.simplebar && this.simplebar.SimpleBar) {

      this.simplebar.SimpleBar.getScrollElement().addEventListener('scroll', (e) => {
        this.esvc.addGuiEvent('scroll', e);
      });

    } else {
      setTimeout(() => {
        this.initSimplebar();
      }, 50);
    }
  }

  // action triggered when user swipes
  swipe(event) {

  }

  tryShowDashboard() {
    if (this.dashboard) {
      this.dashboard.show();
    } else {
      setTimeout(() => {
        this.tryShowDashboard();
      });
    }
  }

  ngOnInit() {
    this.initSimplebar();

    this.loader.loading$.pipe(takeUntil(this._onDestroy)).subscribe(isLoading => {
        if (!isLoading && this.readyLevel1.value) {
          this.dashboard.resize();
        }
    });

    this.readyStateService.readyLevel1.pipe(takeUntil(this._onDestroy)).subscribe(state => {
      this.readyLevel1.next(state);
    })

    this.readyStateService.readyLevel2.pipe(takeUntil(this._onDestroy)).subscribe(state => {
      this.readyLevel2.next(state);
      this.tryShowDashboard();
    })

    this.menubuttonsvc.setMenuPath('/menu');

    this.router.events.pipe(takeUntil(this._onDestroy)).subscribe(ev => {
      if (ev instanceof Scroll) {
        if (ev.routerEvent) {
          ev = ev.routerEvent;
        }
      }
      if (ev instanceof NavigationEnd || ev instanceof NavigationCancel) {
        const r = this.router.url;

        let showType = 'router';

        if (r == '/menu' || r == '/shop' || r == '/admin') {
          showType = 'menu';
        } else if (r == '/' || r == '/dashboard') {
          showType = 'dashboard';
        } else {
          showType = 'router'
        }

        if (showType != this.showType.value) {
          this.showType.next(showType);
        }

        if (r.match(/\/shop\/.*/)) {
          let match = false;
          this.shop_help_button_show_paths.forEach(m => {
            if (r.match(m)) {
              match = true;
            }
          });

          if (match) {
            if (this.show_help_button.value === false) {
              this.show_help_button.next(true);
            }
          } else {
            if (this.show_help_button.value === true) {
              this.show_help_button.next(false);
            }
          }

        } else {
          if (this.show_help_button.value === false) {
            this.show_help_button.next(true);
          }
        }
      }
    });
  }


  ngOnDestroy(): void {
    this._onDestroy.next(true);
    this._onDestroy.complete();
  }
}
